
import { WorkOrderEntityModel, WorkOrderQueryModel } from '@/entity-model/work-order-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, del } from './request';

const URL_PATH = WORKORDER_BASE_REQUEST_PATH;

class WorkOrderService implements ICRUDQ<WorkOrderEntityModel, WorkOrderQueryModel> {
    async create(model: any):Promise<any> {
        throw new Error('Not implement.');
    }

    async retrieve(modelId: string):Promise<any> {
        const url = `${URL_PATH}/process/detail/${modelId}`;
        const res = await get(url);
        return res;
    }

    async update(model: WorkOrderEntityModel):Promise<WorkOrderEntityModel> {
        const url = `${URL_PATH}/workOrderMain`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: WorkOrderEntityModel):Promise<WorkOrderEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        // const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: WorkOrderQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/wf/center/search`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new WorkOrderEntityModel().toModel(item));
        return res;
    }

    /* 导出Excel数据表 */
    async exportExcel(params:any):Promise<any> {
        const url = `${URL_PATH}/wf/center/export`;
        const param = Object.assign({ page: 1, limit: 99 }, params);
        const res = await post(url, param, { responseType: 'blob' });
        return res;
    }
}

export default new WorkOrderService();
