

























































import { Component, Watch, Mixins } from 'vue-property-decorator';
import TableComponent from '@/mixins/table-component';
import DrawerComponent from '@/mixins/drawer-component';
import { WorkOrderEntityModel, WorkOrderQueryModel } from '@/entity-model/work-order-entity';
import WorkOrderService from '@/service/work-order';
import CommonService from '@/service/common';
import { WorkOrderMenuType, ViewModeType, PropertyEntityType } from '@/model/enum';
import ServiceCenterService from '@/service/service-center';
import WorkOrderDetailComponent from './wo-detail.vue';

@Component({
    components: {
        'work-order-detail-component': WorkOrderDetailComponent
    }
})
export default class WorkOrderListComponent extends Mixins(TableComponent, DrawerComponent) {
    WorkOrderEntityModel = WorkOrderEntityModel;

    WorkOrderMenuType = WorkOrderMenuType;

    ViewModeType = ViewModeType;

    viewType: ViewModeType = ViewModeType.VIEW;

    selectedWoConfigId: string = '';

    selectedWorkOrderId: string = '';

    workorderTypeList: any = [];

    @Watch('$route.params')
    routerChange(params): void {
        (this.queryModel as any).searchType = params.searchType as WorkOrderMenuType;

        if (params.workordertype !== 'All') {
            (this.queryModel as any).wOType = _.filter(this.workorderTypeList, type => { return type.code === params.workordertype; })[0].value;
        } else {
            (this.queryModel as any).wOType = JTL.CONSTANT.ALL_OPTION.value;
        }
    }

    get menuType() {
        return _.get(this.queryModel, 'searchType');
    }

    get workorderType() {
        return _.get(this.queryModel, 'workOrderType');
    }

    created() {
        this.init();
    }

    init() {
        let queryModel = new WorkOrderQueryModel();

        CommonService.getPropertyOptions(PropertyEntityType.WORK_ORDER_TYPE_CONF).then(res => {
            this.workorderTypeList = res;

            (queryModel as any).searchType = this.$route.params.searchType as WorkOrderMenuType;

            if (this.$route.params.workordertype !== 'All') {
                (queryModel as any).wOType = _.filter(this.workorderTypeList, type => { return type.code === this.$route.params.workordertype; })[0].value;
            } else {
                (queryModel as any).wOType = JTL.CONSTANT.ALL_OPTION.value;
            }

            this.initTable({ service: WorkOrderService, queryModel: queryModel, tableColumns: WorkOrderEntityModel.getTableColumns() });

            this.getList();
        });
    }

    showModal(viewtype, record) {
        this.viewType = viewtype;

        this.selectedWorkOrderId = record.id;

        this.drawerOpen(record.id);
    }

    showDrawer(info) {
        // 不显示已处理服务请求
        this.getList().then(res => {
            this.listData = this.listData.filter((item:any) => { return item.flowStatus !== 'end'; });
        });

        this.drawerVisible = info.visible;
    }

    claimWorkOrder(record) {
        let service = new ServiceCenterService(false);
        service.saveForm('to_Claim', {}, {}, record.id).then(res => {
            this.showMessageSuccess('认领成功');

            this.$eventHub.$emit('GET_WORKORDER_NUMBERS');

            this.getList();
        });
    }

    exportClick() {
        let params = (this.queryModel as any).toService();

        (this.service as any).exportExcel(params);
    }
}
